export default {
  routes: {
    // product page
    restaurant: '/en/restaurant/:slug',
    bar: '/en/bar/:slug',

    // product list by city
    bar_by_city: '/en/:city/bars',
    restaurant_by_city: '/en/:city/restaurants',

    // product map by city
    bar_by_city_map: '/en/map/:city/bars',
    restaurant_by_city_map: '/en/map/:city/restaurants',

    //take away
    takeaway: '/en/takeaway/:slug',
    takeaway_by_city_map: '/en/map/:city/takeaway',
    takeaway_by_city: '/en/:city/takeaway',

    // product checkout
    book: '/en/book/:step/:productType/:slug',

    // giftcard:
    giftcard: '/en/giftcard/:step',
  },

  // booking people
  people: 'People',
  persons: 'Persons',
  person: 'Person',
  people_not_possible: 'NOT POSSIBLE',
  time_not_possible: 'NOT POSSIBLE',
  all_people: 'All People',
  select_people: 'Select People',
  choose_people: 'Amount of people',

  // booking time
  time_suffix: 'TODAY',
  select_time: 'Select Arrival Time',
  choose_time: 'Pick up time',
  choose_time_restaurant: 'Arrival Time',
  choose_time_bar: 'Arrival Time',
  choose_time_takeaway: 'Arrival Time',

  // booking menu
  choose_menu: 'Choose :num Menu',

  view_menu: 'View Menu',
  order: 'order',

  next: 'Next',
  cancel: 'Cancel',
  accept: 'accept',

  // filter header
  total_filtered: ':productType that match your filtering',
  total_filtered_mobile: [
    "Today's restaurants that match your filtering:",
    '',
  ],

  // giftcard
  giftcard_item: ':quantity GIFT CARD :price',
  giftcard: {
    title: 'gift card',
    amount: 'Write the value of the gift card',
    quantity: 'Amount',
    name: 'Your name',
    email: 'Your e-mail address',
    email_confirm: 'Please re-enter your email address',
    accept_terms: 'I accept the',
    accept_term_link: 'terms',
  },

  overlay_title_showcase: 'NOT ACTIVE TODAY',
  overlay_title_soldout: 'SOLD OUT TODAY',
  overlay_subtitle: 'GET TEMPTED BY ONE OF THE OTHER :productType',

  errors: {
    menu_table_size_limit:
      'The :menu_name can only be ordered by 2, 4 or 6 people',
    'modal-title': ' ',
    menus_num_person: 'Choose menus for :num_person people',
    menu_select_person: 'Please select :num_person before you can proceed',
    time: 'Please choose an arrival time for your booking.',
    num_person: 'Please choose an arrival time for your booking.',
    menus: 'Please choose the menus you would like.',
    name: 'Please enter your name',
    email: 'Please enter your email',
    phone: 'Please enter your mobile',
    'gc-mail-phone':
      'Please insert your e-mail and mobile before you enter the code',
    'gc-exist': 'Gift card is already added',
    'gc-invalid': 'Invalid giftcard',
    'error-gc-expired':
      'The expiration date of the code has been exceeded and the code is therefore no longer active.',
    'error-matas-card': 'ClubMatas no. is invalid',
    'error-gc-status': 'The code has been used and therefore not valid',
    'error-gc-user': 'The code cannot be used on this place.',
    'error-gc-newuser':
      'The code is only valid to new customers who buy for the first time on earlybird.dk',
    'error-gc-maxuse':
      'You have used the discount code earlier and can not use it again.',
    'error-gc-resto': 'The code is not working for this place',
    giftcard_amount: 'Please Write The Value You Want On The Gift Card.',
    email_confirm: 'The Email Addresses Are Not Identical',
    accept_terms: 'The Email Addresses Are Not Identical',
    'bycode-giftcard_validate_cover':
      'The code is only valid for :cover people',
    'bycode-giftcard_validate_user':
      'Unfortunately, the code is only valid for new customers',
    'bycode-giftcard_validate_status':
      'The gift card has already been used',
    'bycode-giftcard_validate_expired':
      'Unfortunately, the code has expired',
    'bycode-giftcard_validate_resto':
      'The gift code is not valid for this place',
    'bycode-giftcard_validate_maxuse':
      'You have already used the code and you can not use it again',
    no_table_available:
      'The last table has just been sold, so you can not complete this order',
    coop_code: 'INVALID MEMBER NUMBER',
    coop_zip: 'Invalid zip code',
  },

  line_item: {
    menus: 'MENUS',
    tax: 'VAT MAKE UP 25%',
    admin_fee: 'ADMINISTRATION FEE',
    payment_total: 'PAYMENT TOTAL',
    giftcode: 'GIFT CODE',
    giftcard_total: 'TOTAL',
    coop: 'COOP MEMBERS NO',
  },

  buttons: {
    ok: 'OK',
    cancel: 'CANCEL',
    yes: 'Yes',
    no: 'No',
  },

  remove_gcode_confirm: 'Will you remove the code?',

  filter_reasons: {
    times: 'All Day',
    area: 'Area',
    person: 'Person',
    tags: 'Køkken & Kitchen stye',
  },

  book_table: 'BOOK TABLE',
  book_table_bar: 'BOOK',
  book_table_restaurant: 'BOOK TABLE',
  book_table_takeaway: 'ORDER',

  book_table_sold: 'SOLD OUT',

  product_page: {
    the_restaurant: 'THE RESTAURANT',
    menu: 'MENU',
  },

  cut_price: 'YOU ALWAYS GET 1/3 CUT OFF THE PRICE WITH EARLY BIRD',

  arrival_time_restaurant: 'Arrival Time',
  arrival_time_bar: 'Arrival Time',
  arrival_time_takeaway: 'Pick up time',

  filter_area: 'Areas',

  times_of_today: 'TIMES OF TODAY',
  times_of_today_bar: 'TIMES OF TODAY',
  times_of_today_restaurant: 'TIMES OF TODAY',
  times_of_today_takeaway: 'PICK UP TIMES TODAY',

  show_menus: 'SHOW MENUS',
  the_resto: 'The Restaurant',
  the_bar: 'The Bar',
  gallery: 'Gallery',
  reset_filter: 'RESET FILTER',
  save_search: 'SHOW :total RESULTS',
  viewtype_list: 'List',
  viewtype_map: 'Map',
  address: 'Address',
  use: 'USE',
  fullname: 'Name',
  emailfield: 'Email Address',
  phonenum: 'Mobile Number',
  giftcode: 'GIFT CARDS & DISCOUNT CODES',
  subscribe:
    'Sign me up for the newsletter about exiting new restaurants. It is send about 2 times a month and can be easily unsubscribed.',
  map_address: 'Address',
  selected: 'selected',
  see_product: "SEE TADAY'S :product",

  BOOK: 'BOOK',
  INFO: 'INFO',
  TERMS: 'TERMS',
  PAYMENT: 'PAYMENT',

  order_giftcard: 'Order Of Gift Cards',

  filter_matcher: {
    doesnt: "DOESN'T",
    search_on: 'MATCH SEARCH ON',
  },

  choose: 'Choose',
  see_todays: "See Today's",
  see_todays_in: 'in',
  more_menu: 'other menus',
  filter_unselectall: 'UNSELECT ALL',
  filter_save: 'SAVE',
  payment_failed_error_msg: 'Payment failed. Please try again.',
  how_many_are_you: 'How many are you?',
  select: 'Select',
  gc_success_msg: ':code has now been reduced from the payment amount',
  change_parent_city: 'Switch to :city',
  view_on_map: 'View on map',
  underserving_tag:
    'Outdoor dining is possible but cannot be reserved.\n Please ask in the restaurant on arrival.',
  menu_select_entire_table:
    'This menu must be selected by the entire table. ',

  menu_type_text: {
    lunch: 'The menu is only served for lunch.',
    dinner: 'The menu is only served in the evening.',
  },
  no_times: 'No available times',
  read_more: 'Read More',
  show_all_resto: 'Show all restaurants',
  search: 'Search',
  remaining_gc_notice:
    'A gift card for the remaining SEK :value will be sent to :email when the order has been completed.',

  message: {
    all_hold_warning:
      'Right now someone else is in the process of buying the last seat and\n therefore it is not possible to continue this booking',
  },
  back: 'Back',
  all: 'ALL',
  zip_code: 'Zip code',
  coop_mem_no: 'Coop members no.',
  coop_member_registered: 'YOUR MEMBER NUMBER IS REGISTERED',
  sweden_vat: 'Prices are in SEK and include VAT',
  menu: 'Menu',
  go_to_payment: "Proceed To Payment",
  booking_restaurant_slug: 'restaurant',
  home: 'Home',
  overlay_restaurant: 'restaurants',
  overlay_takeaway: 'restaurants',
  overlay_bar: 'bars',
};
